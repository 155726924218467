import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchArls(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { 
            params: { perPage: 500, page: 1, companyType: 'ARL', only_active: true } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentTerms(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/billing_payment_term')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchElements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/billing/payment_term', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchElement(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/billing/payment_term/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addElement(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/billing/payment_term`, elementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteElement(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/billing/payment_term/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
